<template>

  <a-row>
    <a-card class="general_card" :bordered="false" title="Kullanıcı Listesi" :loading="loading">
      <template #extra>
        <UnorderedListOutlined/>

        <!--        <router-link class="ant-btn-primary" tag="a" to="/new_company">-->
        <!--          Kullanıcı Ekle-->
        <!--        </router-link>-->

      </template>


      <a-col :span="24">
        <a-table :data-source="users" :columns="columns" @change="handleChange" :row-key="record => record.id">

          <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
            <div style="padding: 8px">
              <a-input
                  ref="searchInput"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)"
              />
              <a-button
                  type="primary"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon>
                  <SearchOutlined/>
                </template>
                Search
              </a-button>
              <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
                Reset
              </a-button>
            </div>
          </template>

          <template #customRender="{ text }">
            {{ text }}
          </template>

          <template #actions="{ record }">

            <a-space>
              <router-link :to="`${ '/users/' + record.id + '/edit'}`" v-slot="{navigate}">
                <a-button type="primary" @click="navigate">
                  <EditOutlined/>
                  Düzenle
                </a-button>
              </router-link>

              <div v-if="!record.is_deleted">
                <a-button danger @click="activeDeactive(record.id)">
                  <DeleteOutlined/>
                  Kapat
                </a-button>
              </div>

              <div v-if="record.is_deleted">
                <a-button type="primary" @click="activeDeactive(record.id)">
                  <UserSwitchOutlined/>
                  Aç
                </a-button>
              </div>


              <div v-if="record.status === 'LOCKED'">
                <a-button type="primary" @click="lockUnlock(record.id)">
                  <UserSwitchOutlined/>
                  Kilidi Kaldır
                </a-button>
              </div>

              <div v-if="record.status !== 'LOCKED'">
                <a-button danger @click="lockUnlock(record.id)">
                  <DeleteOutlined/>
                  Kilitle
                </a-button>
              </div>
            </a-space>


          </template>
        </a-table>
      </a-col>

    </a-card>
  </a-row>
</template>

<script>
import moment from 'moment'
import {EditOutlined, DeleteOutlined, UnorderedListOutlined, UserSwitchOutlined} from '@ant-design/icons-vue'
import {onMounted, reactive, computed, getCurrentInstance, toRefs, ref, inject} from 'vue'
import axios from 'axios'

export default {
  name: 'UserList',
  components: {
    UserSwitchOutlined,
    EditOutlined,
    DeleteOutlined,
    UnorderedListOutlined
  },

  setup() {
    const localState = reactive({users: [], filter: {}})
    const axios = inject('axios')
    const loading = ref(true)

    const state = reactive({
      searchText: '',
      searchedColumn: ''
    })

    const searchInput = ref()

    const loadUser = () => axios.get('/users')
        .then(response => {
          let tempUser = response.data.users

          localState.users = tempUser.map(user => {
            user.created_at = user.created_at ? moment(user.created_at).format('YYYY-MM-DDTH:mm:ss.000000Z') : null
            user.updated_at = moment(user.updated_at).format('YYYY-MM-DDTH:mm:ss.000000Z')
            return user
          })

          loading.value = false

        })

    onMounted(() => {
      loading.value = true
      loadUser()


    })
    const columns = computed(() => {

      return [
        {
          key: 'index',
          title: 'Sıra',
          dataIndex: '_id',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record._id.toString().toLowerCase().includes(value.toLowerCase()),

          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },

        {
          key: '1',
          title: 'ID',
          dataIndex: 'id',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.id.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'name',
          title: 'İsim',
          dataIndex: 'credential.name',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.credential?.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'surname',
          title: 'Soyisim',
          dataIndex: 'credential.surname',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.credential?.surname.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: '2',
          title: 'Kullanıcı adı',
          dataIndex: 'username',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.username.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: '3',
          title: 'Email',
          dataIndex: 'email',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilter: (value, record) =>
              record.email.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {

                searchInput.value.focus()
              }, 100)
            }
          }
        },
        {
          key: 'user_type',
          title: 'Kullanıcı Tipi',
          dataIndex: 'user_type',
          filters: [
            {text: 'USER', value: 'USER'},
            {text: 'STUDENT', value: 'STUDENT'},
            {text: 'AWAITING_USER', value: 'AWAITING_USER'},
            {text: 'EDUCATOR', value: 'EDUCATOR'},
            {text: 'ADMIN', value: 'ADMIN'}
          ],
          filteredValue: localState.filter.user_type || null,
          onFilter: (value, record) => {
            return record.user_type === value
          },
          slots: {
            customRender: 'customRender'
          }
        },
        {
          key: 'user_level_string',
          title: 'Kullanıcı Seviyesi',
          dataIndex: 'user_level_string',
          filters: [
            {text: 'STUDENT', value: 'STUDENT'},
            {text: 'ARTIST', value: 'ARTIST'}
          ],
          filteredValue: localState.filter.user_level_string || null,
          onFilter: (value, record) => {
            return record.user_level_string === value
          },
          slots: {
            customRender: 'customRender'
          }
        },
        {
          key: 'actions',
          dataIndex: 'actions',
          slots: {
            customRender: 'actions'
          }
        }

      ]
    })
    const handleChange = (pagination, filters, sorter) => {
      localState.filter = filters
    }

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = clearFilters => {
      clearFilters()
      state.searchText = ''
    }

    const activeDeactive = (userId) => {
      axios.post(`/users/${userId}/active-deactive`)
          .then(response => {

            loadUser()

          })

    }

    const lockUnlock = (userId) => {
      axios.post(`/users/${userId}/lock-unlock`)
          .then(response => {

            loadUser()

          })

    }


    return {
      lockUnlock,
      activeDeactive,
      ...toRefs(localState),
      columns,
      handleChange,
      loading,
      handleSearch,
      handleReset,
      searchText: '',
      searchInput,
      searchedColumn: ''
    }
  }

}
</script>
<style lang="scss" scoped>

.ant-popover-inner-content p {
  max-width: 295px;
  overflow-x: auto;
  max-height: 50px;
  white-space: nowrap;
  border-bottom: 1px solid #d8d8d8;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background: #d8d8d8;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #1890FF;
    border-radius: 2px;
  }
}
</style>
